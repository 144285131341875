@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #f9f9fb; /* Sehr helles Grau für einen sanften White Mode */
  --secondary-color: #1e6ed8; /* Modernes, kräftiges Blau für Akzente */
  --black-color: #1e293b; /* Dunkles Blau-Grau für Texte */
  --lightGray-color: #d7e6f9; /* Sanftes, helles Grau für dezente UI-Elemente */
  --gray-color: #8ca1c3; /* Weiches Grau-Blau für einen modernen Look */
  --brown-color: #2563eb; /* Etwas kräftigeres Blau als Alternative */
  --white-color: #ffffff; /* Reinweiß für starke Kontraste */
}



* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--white-color);
}