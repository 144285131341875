.app__algorithms {
    flex: 1;
    width: 100%;
    flex-direction: column;
    margin-top: 5rem;
  }

.app__algorithms-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  
    margin: 4rem 0 2rem;
  
    .app__algorithms-filter-item {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      background-color: var(--lightGray-color);
      color: var(--black-color);
      font-weight: 800;
  
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      margin: 0.5rem;
  
      &:hover {
        background-color: var(--secondary-color);
        color: var(--primary-color);
      }
  
      @media screen and (min-width: 2000px) {
        padding: 1rem 2rem;
        border-radius: 0.85rem;
      }
    }
  
    .item-active {
      background-color: var(--secondary-color);
      color: #fff;
    }
}

.app__algorithms-settings {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    min-width: 60vw;

    .app_algorithms-setting {
        flex: 1;
        margin: 2rem;

        input {
            -webkit-appearance: none;
            appearance: none;
            width: 90%;
            margin: auto;
            background: transparent;
            margin-top: 0.5rem;
        }

        input[type=range]::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          height: 15px;
          width: 15px;
          border-radius: 10px;
          background-color: var(--secondary-color);
          cursor: pointer;
          margin-top: -3.5px;
        }

        input[type='range']::-webkit-slider-runnable-track {
          -webkit-border-radius: 15px;
          height: 10px;
          background-color: var(--white-color);
        }


    }

    margin: 1rem 0 2rem;

}

.app_algorithms-setting {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: var(--lightGray-color);
    font-weight: 800;

    cursor: pointer;
    transition: all 0.5s ease-in-out;
    margin: 0.5rem;

    p {
      color: var(--black-color);
    }

    &:hover {
      background-color: var(--secondary-color);

      p {
          color: var(--white-color);
          transition: all 0.5s ease-in-out;
      }
    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
}


.app__algorithms-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: end;
    height: 60vh;
}

.app__algorithms-portfolio div {
    background-color: var(--secondary-color);
    display: inline-block;
    margin: 0;
    margin-left: 0.1vw;
    margin-right: 0.1vw;
    margin-bottom: 5rem;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    position: relative;

    p {
        color: var(--white-color);
        font-weight: bolder;
        text-align: center;
        font-size: 0.5em;
        position: absolute;
        bottom: 10px;
        margin: auto;
        left: 0;
        right: 0;
        text-align: center;

        @media screen and (max-width: 100px) {
            display: none;
          }
    }
}

.app_algorithms-start {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    margin-top: 1.5rem;
}

